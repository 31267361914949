import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Header.css';

function Header() {
    const [scrolled, setScrolled] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const handleScroll = () => {
            setScrolled(window.scrollY > 50);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <header className={`app-header ${scrolled ? 'scrolled' : ''}`}>
            <div className="header-content">
                <Link to="/">
                    <img 
                        src={`${process.env.PUBLIC_URL}/assets/images/company-logo.svg`} 
                        alt="Task Driven Solutions" 
                        className="header-logo" 
                    />
                </Link>
                
                <nav className="nav-links">
                    <Link to="/about" className="nav-link">About</Link>
                    <button 
                        className="header-cta"
                        onClick={() => navigate('/contact')}
                    >
                        Get Started
                    </button>
                </nav>
            </div>
        </header>
    );
}

export default Header;
