export const features = [
  {
    title: 'Custom Software Development',
    description: 'Build robust and scalable software tailored to your business needs with our expert development team.',
    image: `${process.env.PUBLIC_URL}/assets/images/custom-software-development.webp`
  },
  {
    title: 'UI/UX Design and Implementation',
    description: 'Deliver intuitive and visually compelling interfaces with our expert UI/UX design services.',
    image: `${process.env.PUBLIC_URL}/assets/images/ui-ux-design.webp`
  },
  {
    title: 'Invoice Processing',
    description: 'Simplify your invoicing process and get paid faster with automated invoice creation, delivery, and tracking.',
    image: `${process.env.PUBLIC_URL}/assets/images/invoice-processing.webp`,
  },
  {
    title: 'Project Management',
    description: 'Keep your projects on track effortlessly by automating task assignments, progress tracking, and timelines.',
    image: `${process.env.PUBLIC_URL}/assets/images/project-management.webp`,
  },
  {
    title: 'Customer Support',
    description: 'Enhance your customer experience by automating ticket routing, responses, and support workflows for faster resolutions.',
    image: `${process.env.PUBLIC_URL}/assets/images/customer-support.webp`,
  },
  {
    title: 'Social Media Management',
    description: 'Save time and grow your brand with automated post scheduling, engagement tracking, and performance reporting.',
    image: `${process.env.PUBLIC_URL}/assets/images/social-media.webp`,
  },
  {
    title: 'Email Marketing Campaigns',
    description: 'Boost engagement with automated, personalized email campaigns, follow-ups, and newsletters.',
    image: `${process.env.PUBLIC_URL}/assets/images/email-marketing.webp`,
  },
  {
    title: 'Inventory Management',
    description: 'Maintain perfect stock levels with automated inventory tracking and timely reorders.',
    image: `${process.env.PUBLIC_URL}/assets/images/inventory-management.webp`,
  },
  {
    title: 'Custom Automations',
    description: 'Design custom automation workflows that fit your unique business challenges and drive optimal results.',
    image: `${process.env.PUBLIC_URL}/assets/images/custom-automations.webp`,
  },
  {
    title: 'Training',
    description: 'Empower your team with beginner to expert-level AI and automation training, tailored to your industry needs.',
    image: `${process.env.PUBLIC_URL}/assets/images/training.webp`,
  },
];

  
  export const pitches = [
    {
      title: 'Comprehensive Software Development',
      description: 'We deliver end-to-end software solutions tailored to your business needs, ensuring scalability, efficiency, and innovation.',
      image: `${process.env.PUBLIC_URL}/assets/images/software-development.webp`
    },
    {
        title: 'Streamlined Workflows',
        description: 'Supercharge your operations by automating time-consuming tasks and optimizing every workflow for maximum efficiency.',
        image: `${process.env.PUBLIC_URL}/assets/images/automation-process.webp`
    },
    {
        title: 'Advanced AI Solutions',
        description: 'Harness the power of advanced AI and machine learning to automate complex processes and stay ahead of the competition.',
        image: `${process.env.PUBLIC_URL}/assets/images/ai-technology.webp`
    },
    {
        title: 'Seamless Integration',
        description: 'Effortlessly connect our automation solutions to your existing systems for a smooth and disruption-free integration.',
        image: `${process.env.PUBLIC_URL}/assets/images/collaborative-tools.webp`
    }
  ]