import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { features, pitches } from '../../misc/objects';
import './Service.css';

function Service() {
    const { serviceId } = useParams();
    const navigate = useNavigate();

    // Scroll to top when component mounts
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // Find the service from either features or pitches
    const findService = () => {
        const normalizedServiceId = serviceId.toLowerCase();
        
        // First check features
        const feature = features.find(f => 
            f.title.toLowerCase().replace(/[^a-z0-9]+/g, '-') === normalizedServiceId
        );
        if (feature) return feature;

        // Then check pitches
        const pitch = pitches.find(p => 
            p.title.toLowerCase().replace(/[^a-z0-9]+/g, '-') === normalizedServiceId
        );
        if (pitch) return pitch;

        return null;
    };

    const service = findService();

    if (!service) {
        return (
            <>
                <Header />
                <div className="service-page">
                    <div className="service-not-found">
                        <h1>Service Not Found</h1>
                        <p>The service you're looking for doesn't exist.</p>
                        <button className="cta-button" onClick={() => navigate('/')}>
                            Return Home
                        </button>
                    </div>
                </div>
                <Footer />
            </>
        );
    }

    // Define service details based on the found service
    const serviceDetails = {
        title: service.title,
        tagline: `Transform your business with our ${service.title.toLowerCase()} solutions`,
        description: service.description,
        image: service.image,
        benefits: [
            "Increased efficiency and productivity",
            "Reduced operational costs",
            "Improved customer satisfaction",
            "Scalable and future-proof solutions",
            "Expert support and maintenance"
        ],
        metrics: [
            { value: "40%", label: "Average Efficiency Increase" },
            { value: "60%", label: "Cost Reduction" },
            { value: "99%", label: "Client Satisfaction Rate" }
        ],
        successStory: {
            title: "Client Success Story",
            content: `A leading company in the industry achieved remarkable results with our ${service.title.toLowerCase()} solution.`,
            metrics: "They saw a 40% increase in efficiency and 60% reduction in operational costs within the first 6 months.",
            quote: "The implementation exceeded our expectations. The team's expertise and dedication made the transition seamless."
        }
    };

    return (
        <>
            <Header />
            <main className="service-page">
                <section className="service-hero">
                    <div className="service-hero-content">
                        <h1>{serviceDetails.title}</h1>
                        <p className="service-tagline">{serviceDetails.tagline}</p>
                        <p className="service-description">{serviceDetails.description}</p>
                        <button className="cta-button" onClick={() => navigate('/contact')}>
                            Schedule Consultation
                        </button>
                    </div>
                    <div className="service-hero-image">
                        <img src={serviceDetails.image} alt={serviceDetails.title} />
                    </div>
                </section>

                <section className="value-props">
                    <h2>Key Benefits</h2>
                    <div className="value-props-grid">
                        {serviceDetails.metrics.map((metric, index) => (
                            <div key={index} className="value-prop-item">
                                <div className="metric">{metric.value}</div>
                                <h3>{metric.label}</h3>
                                <p>Experience significant improvements in your business metrics</p>
                            </div>
                        ))}
                    </div>
                </section>

                <section className="why-choose-us">
                    <h2>Why Choose Our Solution</h2>
                    <div className="why-us-grid">
                        {serviceDetails.benefits.map((benefit, index) => (
                            <div key={index} className="why-us-item">
                                <div className="check-icon">✓</div>
                                <p>{benefit}</p>
                            </div>
                        ))}
                    </div>
                </section>

                <section className="success-story">
                    <h2>{serviceDetails.successStory.title}</h2>
                    <div className="success-story-content">
                        <h3>Real Results, Real Impact</h3>
                        <p className="result">{serviceDetails.successStory.metrics}</p>
                        <blockquote>
                            {serviceDetails.successStory.quote}
                        </blockquote>
                        <div className="success-metrics">
                            <span className="metric-item">40% Efficiency Boost</span>
                            <span className="metric-item">60% Cost Savings</span>
                            <span className="metric-item">99% Satisfaction</span>
                        </div>
                    </div>
                </section>

                <section className="service-cta">
                    <h2>Ready to Transform Your Business?</h2>
                    <p>Let's discuss how our {serviceDetails.title.toLowerCase()} solutions can help you achieve your goals.</p>
                    <button className="cta-button" onClick={() => navigate('/contact')}>
                        Schedule a Free Consultation
                    </button>
                </section>
            </main>
            <Footer />
        </>
    );
}

export default Service;
