import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebook, FaLinkedin, FaEnvelope, FaMapMarkerAlt, FaPhone } from 'react-icons/fa';
import './Footer.css';

function Footer() {
    return (
        <footer className="footer">
            <div className="footer-container">
                <div className="footer-brand">
                    <img 
                        src={`${process.env.PUBLIC_URL}/assets/images/company-logo.svg`}
                        alt="Task Driven Solutions" 
                        className="footer-logo"
                    />
                    <p className="footer-tagline">
                        Transforming ideas into exceptional digital experiences. 
                        Your vision, our expertise.
                    </p>
                </div>

                <div className="footer-links">
                    <h3>Quick Links</h3>
                    <Link to="/about" className="footer-link">About Us</Link>
                    <Link to="/contact" className="footer-link">Contact</Link>
                </div>

                <div className="footer-contact">
                    <h3>Get in Touch</h3>
                    <div className="contact-info">
                        <div className="contact-item">
                            <FaEnvelope />
                            <span>info@taskdrivensolutions.com</span>
                        </div>
                        <div className="contact-item">
                            <FaPhone />
                            <span>+1 (937) 914-3995</span>
                        </div>
                        <div className="contact-item">
                            <FaMapMarkerAlt />
                            <span>Dayton, Ohio</span>
                        </div>
                    </div>
                    <div className="footer-social">
                        <a href="https://facebook.com" className="social-link" target="_blank" rel="noopener noreferrer">
                            <FaFacebook size={24} />
                        </a>
                        <a href="https://linkedin.com" className="social-link" target="_blank" rel="noopener noreferrer">
                            <FaLinkedin size={24} />
                        </a>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <p>&copy; {new Date().getFullYear()} Task Driven Solutions. All rights reserved.</p>
            </div>
        </footer>
    );
}

export default Footer;
