// Features.js
import React from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import './Features.css'; // Import CSS styles
import { features } from '../../misc/objects';

function Features() {
  return (
    <>
      <Header />
      <div className="features-container">
        <h2 className="features-title">Revolutionize Your Business with These Automated Processes</h2>
        <p className="features-description">
          Experience faster workflows, reduced costs, and optimized efficiency with our diverse range of business automation solutions, designed to scale with your needs.
        </p>
        <div className="features-grid">
          {features.map((feature, index) => (
            <div key={index} className="feature-card">
              <img src={feature.image} alt={feature.title} className="feature-image" />
              <h3 className="feature-title">{feature.title}</h3>
              <p className="feature-description">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
      <div>
          <Footer />
      </div>
    </>
  );
}

export default Features;
