// Contact.js
import React, { useState, useEffect } from 'react';
import Header from '../../components/Header/Header';
import { sendContactInfo } from '../../misc/misc_functions';
import Footer from '../../components/Footer/Footer';
import './Contact.css';

function Contact() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    description: '',
  });

  // Scroll to top when component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [responseMessage, setResponseMessage] = useState('');
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    // Clear error when user starts typing
    if (errors[e.target.name]) {
      setErrors({ ...errors, [e.target.name]: '' });
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.email.includes('@')) {
      newErrors.email = 'Please enter a valid email address';
    }
    if (formData.phone.length < 10) {
      newErrors.phone = 'Please enter a valid phone number';
    }
    if (formData.description.length < 10) {
      newErrors.description = 'Please provide more details about your project';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsSubmitting(true);
      try {
        await sendContactInfo(formData);
        setResponseMessage('Thank you for reaching out! Our team will contact you within 1 business day.');
        setFormData({
          firstName: '',
          lastName: '',
          company: '',
          email: '',
          phone: '',
          description: '',
        });
      } catch (error) {
        setResponseMessage('Oops! Something went wrong. Please try again or contact us directly.');
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <>
      <Header />
      <div className="contact">
        <div className="contact-hero">
          <h2>Let's Build Something Amazing Together</h2>
          <div className="contact-description-container">
            <p className="contact-description">
              Ready to transform your business with cutting-edge software solutions? Our experts are here to help bring your vision to life with tailored, end-to-end services designed for your unique needs.
            </p>
          </div>
        </div>

        <div className="contact-form-container">
          <form onSubmit={handleSubmit} className="contact-form">
            <div className="form-group">
              <label htmlFor="firstName">First Name *</label>
              <input
                id="firstName"
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="lastName">Last Name *</label>
              <input
                id="lastName"
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="company">Company Name *</label>
              <input
                id="company"
                type="text"
                name="company"
                value={formData.company}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email Address *</label>
              <input
                id="email"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              {errors.email && <p className="error">{errors.email}</p>}
            </div>
            <div className="form-group">
              <label htmlFor="phone">Phone Number *</label>
              <input
                id="phone"
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
              {errors.phone && <p className="error">{errors.phone}</p>}
            </div>
            <div className="form-group">
              <label htmlFor="description">Tell Us About Your Project *</label>
              <textarea
                id="description"
                name="description"
                value={formData.description}
                onChange={handleChange}
                required
                placeholder="What challenges are you looking to solve? What are your goals?"
              />
              {errors.description && <p className="error">{errors.description}</p>}
            </div>
            <button 
              type="submit" 
              className="submit-button"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Sending...' : 'Get Started'}
            </button>
          </form>
          {responseMessage && (
            <p className="response-message">{responseMessage}</p>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Contact;
