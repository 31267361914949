import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import './About.css';

function About() {
    const navigate = useNavigate();

    const expertise = [
        {
            title: "Software Engineering",
            description: "Extensive experience in developing robust, scalable software solutions across various platforms and technologies."
        },
        {
            title: "Sales Engineering",
            description: "Bridging the gap between technical capabilities and business needs, delivering solutions that drive real value."
        },
        {
            title: "Network Engineering",
            description: "Deep understanding of network architecture and infrastructure, ensuring reliable and secure system implementations."
        },
        {
            title: "Product Management",
            description: "Strategic product development and lifecycle management, focusing on market needs and user experience."
        }
    ];

    const stats = [
        {
            number: "25+",
            label: "Years Experience"
        },
        {
            number: "500+",
            label: "Projects Completed"
        },
        {
            number: "100%",
            label: "Client Satisfaction"
        },
        {
            number: "24/7",
            label: "Support Available"
        }
    ];

    return (
        <>
            <Header />
            <main className="about-page">
                <section className="about-hero">
                    <h1>Transforming Ideas Into Reality</h1>
                    <p>
                        With 25 years of experience in the dynamic field of software development 
                        and technology, we've built a reputation for delivering exceptional 
                        solutions that drive business success. Our expertise spans multiple 
                        disciplines, allowing us to provide comprehensive solutions that address 
                        the full spectrum of your technology needs.
                    </p>
                </section>

                <section className="experience-section">
                    <div className="experience-grid">
                        {expertise.map((item, index) => (
                            <div key={index} className="experience-item">
                                <h3>{item.title}</h3>
                                <p>{item.description}</p>
                            </div>
                        ))}
                    </div>
                </section>

                <section className="stats-section">
                    <h2>Our Impact By The Numbers</h2>
                    <div className="stats-grid">
                        {stats.map((stat, index) => (
                            <div key={index} className="stat-item">
                                <div className="stat-number">{stat.number}</div>
                                <div className="stat-label">{stat.label}</div>
                            </div>
                        ))}
                    </div>
                </section>

                <section className="cta-section">
                    <h2>Ready to Build Something Amazing?</h2>
                    <p>
                        Let's combine our expertise with your vision to create 
                        innovative solutions that propel your business forward.
                    </p>
                    <button 
                        className="cta-button"
                        onClick={() => navigate('/contact')}
                    >
                        Start Your Project
                    </button>
                </section>
            </main>
            <Footer />
        </>
    );
}

export default About;
